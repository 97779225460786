import React from 'react'
import { styled } from '@creditas/stylitas'
import { ActionLink } from '@creditas/button'
import { Navigation } from '@creditas/icons'

const CustomActionLink = styled(ActionLink)`
  position: absolute;
  padding: 0;
  right: 0;
  bottom: -40px;
`

interface Props {
  onClick: Function
}

const CardBtnSeePreviousItem: React.FC<Props> = ({ onClick, children }) => {
  return (
    <CustomActionLink onClick={onClick} icon={Navigation.ChevronRight}>
      {children}
    </CustomActionLink>
  )
}

export { CardBtnSeePreviousItem }
