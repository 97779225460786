import { styled } from '@creditas/stylitas'

export const BtnsBox = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 39px;
  width: 100%;

  @media (max-width: 545px) {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  > button {
    min-width: 240px;

    @media (max-width: 545px) {
      min-width: 100%;

      &:first-of-type {
        margin-top: 10px;
        order: 2;
      }

      &:last-of-type {
        order: 1;
      }
    }
  }
`
