import React from 'react'
import { progressBar } from './progressBar.style'
import { styled, css } from '@creditas/stylitas'

interface Props {
  percentage: number
}

const ProgressBarItem = styled.div<{ percentage: number }>`
  ${progressBar};

  &:after {
    ${({ theme, ...props }: any) =>
      props.percentage &&
      css`
        width: ${props.percentage}%;
      `}
  }
`

const ProgressBar: React.FC<Props> = ({ percentage }) => {
  return <ProgressBarItem percentage={percentage} />
}

export { ProgressBar }
