import { css, styled } from '@creditas-ui/system'
import { getTypography } from '@creditas-ui/utilities'
import { ButtonPrimaryTextOnly as ButtonRoot } from '@creditas-ui/button'

interface ButtonPrimaryProps {
  marginTop?: number
}

const ButtonPrimary = styled(ButtonRoot)<ButtonPrimaryProps>(
  ({ theme, marginTop }) => css`
    min-width: 100%;
    margin-top: ${marginTop ? marginTop : '0'}px;
    ${getTypography('bodyMdMedium')({ theme })}
  `,
)

export { ButtonPrimary }
