import React from 'react'
import { Button } from '@creditas/button'

import {
  Card,
  CardTitle,
  CardResume,
  CardSubtitle,
  CardResumeBox,
  CardBtnRedirect,
  CardResumeBoxInfo,
  CardResumeBoxLabel,
  CardResumeBoxHighlight,
} from '../Card'

import { ProgressBar } from '..'
import { BtnsBox } from './storeCurrentCard.style'
import { useTranslation, Trans } from 'react-i18next'
import { CurrentCardStoreData } from '../../interfaces'

interface Props {
  data: CurrentCardStoreData
}

const StoreCurrentCard = ({ data }: Props) => {
  const { t } = useTranslation()

  const redirectTo = (url: string) => {
    window.singleSpaNavigate(url)
  }

  return (
    <Card>
      <CardTitle>{t('cards.StoreCurrentCard.title')}</CardTitle>
      <CardSubtitle>{data.productDescription}</CardSubtitle>

      <CardBtnRedirect
        callbackLocation={_ => redirectTo(data.urlProductInternal)}
      />

      <ProgressBar percentage={data.progressBarPercentage} />

      <CardResume>
        <CardResumeBox>
          <CardResumeBoxLabel>
            <Trans i18nKey="cards.StoreCurrentCard.label1">
              <span></span>
            </Trans>
          </CardResumeBoxLabel>
          <CardResumeBoxHighlight color={'bColorprimary04'}>
            {data.totalInstallmentsPaid}
          </CardResumeBoxHighlight>
          <CardResumeBoxInfo>{data.amountPaid}</CardResumeBoxInfo>
        </CardResumeBox>

        <CardResumeBox right={true}>
          <CardResumeBoxLabel>
            <Trans i18nKey="cards.StoreCurrentCard.label2">
              <span></span>
            </Trans>
          </CardResumeBoxLabel>
          <CardResumeBoxHighlight color={'bColorneutral08'}>
            {data.totalInstallmentsOpened}
          </CardResumeBoxHighlight>
          <CardResumeBoxInfo>{data.amountOpened}</CardResumeBoxInfo>
        </CardResumeBox>
      </CardResume>

      <BtnsBox>
        {data.buttons?.installmentsAdvance && (
          <Button
            variant="tertiary"
            onClick={_ => redirectTo(data.buttons.installmentsAdvanceUrl)}
          >
            {t('cards.StoreCurrentCard.action')}
          </Button>
        )}
      </BtnsBox>
    </Card>
  )
}

export { StoreCurrentCard }
