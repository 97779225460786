import React from 'react'
import BTimePositive from '@creditas/brand-icons/dist/general/BTimePositive'
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardTitle,
  CardDescription,
  CardGrid,
  CardContent,
  CardIcon,
  CardResume,
  CardResumeBox,
  CardResumeBoxLabel,
  CardResumeBoxHighlight,
} from '../Card'

import { WaitingApprovalCardLoanData } from '../../interfaces'
import { Button } from './loanWaitingApprovalCard.style'

interface Props {
  data: WaitingApprovalCardLoanData
}

const LoanWaitingApprovalCard = ({ data }: Props) => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardGrid>
        <CardIcon>
          <BTimePositive fontSize="default" />
        </CardIcon>
        <CardContent>
          <CardTitle>{t('cards.LoanWaitingApprovalCard.title')}</CardTitle>
          <CardDescription>
            {t('cards.LoanWaitingApprovalCard.description')}
          </CardDescription>
          <CardResume>
            <CardResumeBox>
              <CardResumeBoxLabel>
                {t('cards.LoanWaitingApprovalCard.label1')}
              </CardResumeBoxLabel>
              <CardResumeBoxHighlight color="bColorprimary04">
                {data.amount}
              </CardResumeBoxHighlight>
            </CardResumeBox>

            <CardResumeBox right={true}>
              <CardResumeBoxLabel>
                {t('cards.LoanWaitingApprovalCard.label2')}
              </CardResumeBoxLabel>
              <CardResumeBoxHighlight color="bColorprimary04">
                {data.totalInstallments}
              </CardResumeBoxHighlight>
            </CardResumeBox>
          </CardResume>
        </CardContent>
      </CardGrid>
      <Button
        variant="secondary"
        onClick={() => window.singleSpaNavigate(data.redirectTo)}
      >
        {t('cards.LoanWaitingApprovalCard.action')}
      </Button>
    </Card>
  )
}

export { LoanWaitingApprovalCard }
