import { css, utilities } from '@creditas/stylitas'

export const card = ({ theme }) => css`
  background-color: white;
  border: 1px solid ${theme.bColorneutral03};
  border-radius: 4px;
  color: ${theme.bColorneutral07};
  padding: 24px;
  position: relative;
  width: 100%;
  margin-bottom: 56px;

  @media (max-width: 500px) {
    padding: 15px;
  }
`

export const cardTitle = ({ theme }) => css`
  color: ${theme.bColorneutral08};
  ${utilities.typography.h5(theme)};
  margin-right: 30px;
`

export const cardSubtitle = ({ theme }) => css`
  color: ${theme.bColorneutral08};
  margin-top: 6px;
  ${utilities.typography.h6(theme)};
`

export const cardDescription = ({ theme }) => css`
  ${utilities.typography.support(theme)};
  margin-top: 8px;
`

export const cardResume = ({ theme }) => css`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  margin-top: 24px;

  @media (max-width: 500px) {
    margin-top: 16px;
  }
`

export const cardResumeBox = ({ theme }) => css`
  display: flex;
  flex-direction: column;

  &:first-of-type {
    margin-right: 5px;
  }
`

export const cardResumeBoxLabel = ({ theme }) => css`
  ${utilities.typography.support(theme)};
  font-weight: inherit;
`

export const cardResumeBoxInfo = ({ theme }) => css`
  ${utilities.typography.support(theme)};
`

export const cardBtnRedirect = ({ theme }) => css`
  outline: none;
  cursor: pointer;
  user-select: none;
  background: none;
  position: absolute;
  top: 15px;
  right: 8px;
  border: 0;

  @media (max-width: 500px) {
    top: 8px;
    right: 0px;
  }

  > svg {
    fill: ${theme.bColorsecondary05};
    font-size: 48px;
  }
`
