import React from 'react'
import BTimePositive from '@creditas/brand-icons/dist/general/BTimePositive'
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardTitle,
  CardDescription,
  CardGrid,
  CardContent,
  CardIcon,
  CardResume,
  CardResumeBox,
  CardResumeBoxLabel,
  CardResumeBoxHighlight,
  CardBtnSeePreviousItem,
} from '../Card'

import { RefinancingWaitingApprovalData } from '../../interfaces'

interface Props {
  data: RefinancingWaitingApprovalData
}

const RefinancingWaitingApprovalCard = ({ data }: Props) => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardGrid>
        <CardIcon>
          <BTimePositive fontSize="default" />
        </CardIcon>
        <CardContent>
          <CardTitle>
            {t('cards.RefinancingWaitingApprovalCard.title')}
          </CardTitle>
          <CardDescription>
            {t('cards.RefinancingWaitingApprovalCard.description')}
          </CardDescription>
          <CardResume>
            <CardResumeBox>
              <CardResumeBoxLabel>
                {t('cards.RefinancingWaitingApprovalCard.label1')}
              </CardResumeBoxLabel>
              <CardResumeBoxHighlight color="bColorneutral08">
                {data.amount}
              </CardResumeBoxHighlight>
            </CardResumeBox>

            <CardResumeBox right={true}>
              <CardResumeBoxLabel>
                {t('cards.RefinancingWaitingApprovalCard.label2')}
              </CardResumeBoxLabel>
              <CardResumeBoxHighlight color="bColorneutral08">
                {data.totalInstallments}
              </CardResumeBoxHighlight>
            </CardResumeBox>
          </CardResume>

          <CardResume>
            <CardResumeBox>
              <CardResumeBoxLabel>
                {t('cards.RefinancingWaitingApprovalCard.label3')}
              </CardResumeBoxLabel>
              <CardResumeBoxHighlight color="bColorprimary04">
                {data.netValue}
              </CardResumeBoxHighlight>
            </CardResumeBox>
          </CardResume>
        </CardContent>
      </CardGrid>

      {data.seePreviousItems && (
        <CardBtnSeePreviousItem
          onClick={_ => window.singleSpaNavigate(data.seePreviousItemsHref)}
        >
          {t('cards.RefinancingWaitingApprovalCard.action')}
        </CardBtnSeePreviousItem>
      )}
    </Card>
  )
}

export { RefinancingWaitingApprovalCard }
