import { css, styled } from '@creditas/stylitas'
import { ActionLink } from '@creditas/button'

const GenericTextStyles = () => css`
  font-size: 14px;
  line-height: 24px;
`

export const InformationCardContainer = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: #fff8f3;
  padding: 16px;
  border-radius: 4px;
`

export const TextContainer = styled.div`
  padding-right: 16px;
`

export const TextContent = styled.p`
  ${GenericTextStyles}
`

export const LinkContent = styled(ActionLink)`
  ${GenericTextStyles}
  padding: 0;
  font-weight: 700;
  color: #4785c2;
  text-decoration: none;
`
