import { styled } from '@creditas/stylitas'

export const BtnsBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
  width: 100%;

  > button {
    min-width: 240px;

    @media (max-width: 545px) {
      min-width: 100%;
    }
  }
`
