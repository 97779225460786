import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from './loanAvailableCard.style'

import {
  Card,
  CardTitle,
  CardDescription,
  CardResume,
  CardResumeBox,
  CardResumeBoxInfo,
  CardResumeBoxLabel,
  CardResumeBoxHighlight,
  CardBtnSeePreviousItem,
} from '../Card'

import { AvailableCardLoanData } from '../../interfaces'

interface Props {
  data: AvailableCardLoanData
}

const LoanAvailableCard = ({ data }: Props) => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardTitle>{t('cards.LoanAvailableCard.title')}</CardTitle>
      <CardDescription>
        {t('cards.LoanAvailableCard.description')}
      </CardDescription>
      <CardResume>
        <CardResumeBox>
          <CardResumeBoxLabel>
            {t('cards.LoanAvailableCard.label1')}
          </CardResumeBoxLabel>
          <CardResumeBoxHighlight color={'bColorprimary04'}>
            {data.amount}
          </CardResumeBoxHighlight>
          <CardResumeBoxInfo>
            {t('cards.LoanAvailableCard.info1')}
          </CardResumeBoxInfo>
        </CardResumeBox>

        <CardResumeBox right={true}>
          <CardResumeBoxLabel>
            {t('cards.LoanAvailableCard.label2')}
          </CardResumeBoxLabel>
          <CardResumeBoxHighlight color={'bColorprimary04'}>
            {data.totalInstallments}
          </CardResumeBoxHighlight>
          <CardResumeBoxInfo>
            {t('cards.LoanAvailableCard.info2')} {data.tax}%
          </CardResumeBoxInfo>
        </CardResumeBox>
      </CardResume>

      <Button
        variant="secondary"
        onClick={() => window.singleSpaNavigate(data.redirectTo)}
      >
        {t('cards.LoanAvailableCard.action')}
      </Button>
    </Card>
  )
}

export { LoanAvailableCard }
