import React from 'react'
import BInfoPositive from '@creditas/brand-icons/dist/cS/BInfoPositive'
import { useTranslation } from 'react-i18next'
import { Internet, Notification } from '@creditas/icons'

import {
  Card,
  CardTitle,
  CardDescription,
  CardResume,
  CardResumeBoxLabel,
} from '../Card'
import {
  ContactsContainer,
  IconContainer,
  Button,
  CardGrid,
  CardIconContainer,
  CardTitleContainer,
} from './loanTerminatedCard.style'

const LoanTerminatedCard = () => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardGrid>
        <CardIconContainer>
          <BInfoPositive fontSize="small" />
        </CardIconContainer>
        <CardTitleContainer>
          <CardTitle>{t('cards.LoanTerminatedCard.title')}</CardTitle>
        </CardTitleContainer>
      </CardGrid>

      <CardDescription>
        {t('cards.LoanTerminatedCard.description')}
      </CardDescription>

      <CardResume>{t('cards.LoanTerminatedCard.label1')}</CardResume>

      <ContactsContainer>
        <IconContainer>
          <Notification.Phone color="#23a969" />
          <CardResumeBoxLabel>11 3164-1402</CardResumeBoxLabel>
        </IconContainer>
        <IconContainer>
          <Internet.AtSign color="#23a969" />
          <CardResumeBoxLabel>atendimento@creditas.com.br</CardResumeBoxLabel>
        </IconContainer>
      </ContactsContainer>

      <CardResume>{t('cards.LoanTerminatedCard.label2')}</CardResume>

      <Button
        variant="secondary"
        onClick={() =>
          window.singleSpaNavigate('https://negocie.creditas.com.br/')
        }
      >
        {t('cards.LoanTerminatedCard.action')}
      </Button>
    </Card>
  )
}

export { LoanTerminatedCard }
