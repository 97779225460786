import React from 'react'
import { Button } from '@creditas/button'

import {
  Card,
  CardTitle,
  CardResume,
  CardResumeBox,
  CardBtnRedirect,
  CardResumeBoxInfo,
  CardResumeBoxLabel,
  CardResumeBoxHighlight,
} from '../Card'

import { ProgressBar } from '..'
import { BtnsBox } from './creditCardCurrentCard.styl'
import { useTranslation, Trans } from 'react-i18next'

import { CurrentCardLoanData } from '../../interfaces'

interface Props {
  data: CurrentCardLoanData
}

const CreditCardCurrentCard = ({ data }: Props) => {
  const { t } = useTranslation()

  const redirectTo = (url: string) => {
    window.singleSpaNavigate(url)
  }

  return (
    <Card>
      <CardTitle>{t('cards.CreditCardCurrentCard.title')}</CardTitle>

      <CardBtnRedirect
        callbackLocation={_ => redirectTo(data.urlProductInternal)}
      />

      <ProgressBar percentage={data.progressBarPercentage} />

      <CardResume>
        <CardResumeBox>
          <CardResumeBoxLabel>
            <Trans i18nKey="cards.CreditCardCurrentCard.label1">
              <span></span>
            </Trans>
          </CardResumeBoxLabel>
          <CardResumeBoxHighlight color={'bColorprimary04'}>
            {data.totalInstallmentsPaid}
          </CardResumeBoxHighlight>
          <CardResumeBoxInfo>{data.amountPaid}</CardResumeBoxInfo>
        </CardResumeBox>

        <CardResumeBox right={true}>
          <CardResumeBoxLabel>
            <Trans i18nKey="cards.CreditCardCurrentCard.label2">
              <span></span>
            </Trans>
          </CardResumeBoxLabel>
          <CardResumeBoxHighlight>
            {data.totalInstallmentsOpened}
          </CardResumeBoxHighlight>
          <CardResumeBoxInfo>{data.amountOpened}</CardResumeBoxInfo>
        </CardResumeBox>
      </CardResume>

      <BtnsBox>
        {data.buttons?.installmentsAdvance && (
          <Button
            variant="tertiary"
            onClick={_ => redirectTo(data.buttons.installmentsAdvanceUrl)}
          >
            {t('cards.CreditCardCurrentCard.actionSecondary')}
          </Button>
        )}

        {data.buttons?.refinance && (
          <Button
            variant="secondary"
            onClick={_ => redirectTo(data.buttons.refinanceUrl)}
          >
            {t('cards.CreditCardCurrentCard.action')}
          </Button>
        )}
      </BtnsBox>
    </Card>
  )
}

export { CreditCardCurrentCard }
