import { styled, css, utilities } from '@creditas/stylitas'
import {
  cardResume,
  cardResumeBox,
  cardResumeBoxInfo,
  cardResumeBoxLabel,
} from './card.style'

const CardResumeBox = styled.div<{ right?: boolean }>`
  ${cardResumeBox}

  ${({ right }) =>
    right &&
    css`
      text-align: right;
    `}
`

interface PropsHighlight {
  color?: string
}

const CardResumeBoxHighlight = styled.strong<PropsHighlight>`
  ${({ theme }) => css`
    ${utilities.typography.h5(theme)};
  `}

  ${({ theme, color }: any) =>
    color &&
    css`
      color: ${theme[color ? color : 'bColorneutral08']};
    `}
`

const CardResumeBoxLabel = styled.h3`
  ${cardResumeBoxLabel}

  > span {
    @media (max-width: 400px) {
      display: block;
    }
  }
`

const CardResumeBoxInfo = styled.p`
  ${cardResumeBoxInfo}
`

const CardResume = styled.div`
  ${cardResume}
`

export {
  CardResume,
  CardResumeBox,
  CardResumeBoxInfo,
  CardResumeBoxLabel,
  CardResumeBoxHighlight,
}
