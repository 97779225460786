import React from 'react'
import {
  InformationCardContainer,
  TextContainer,
  TextContent,
  LinkContent,
} from './InformationCard.styles'

interface InformationCardProps {
  text: string
  linkText: string
  linkHandler: () => void
}

export const InformationCard = ({
  text = '',
  linkText = '',
  linkHandler,
}: InformationCardProps) => {
  return (
    <InformationCardContainer>
      <TextContainer>
        <TextContent>
          {text} <LinkContent onClick={linkHandler}>{linkText}</LinkContent>
        </TextContent>
      </TextContainer>
      <img
        height={34}
        width={34}
        alt="warning"
        src={'/payroll-loan-status/assets/warning-alert.svg'}
      />
    </InformationCardContainer>
  )
}
