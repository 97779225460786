import { styled } from '@creditas/stylitas'

const CardInfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  margin: 24px 0;
`

export { CardInfosContainer }
