import React from 'react'
import { styled } from '@creditas/stylitas'
import { Action } from '@creditas/icons'
import { cardBtnRedirect } from './card.style'

const Button = styled.button`
  ${cardBtnRedirect}
`

interface Props {
  callbackLocation: any
}

const CardBtnRedirect: React.FC<Props> = ({ callbackLocation }) => {
  return (
    <Button onClick={callbackLocation}>
      <Action.Eye />
    </Button>
  )
}

export { CardBtnRedirect }
