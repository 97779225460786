import React from 'react'
import BTimePositive from '@creditas/brand-icons/dist/general/BTimePositive'
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardGrid,
  CardIcon,
  CardTitle,
  CardResume,
  CardContent,
  CardSubtitle,
  CardResumeBox,
  CardDescription,
  CardResumeBoxLabel,
  CardResumeBoxHighlight,
} from '../Card'

import { WaitingApprovalCardStoreData } from '../../interfaces'

interface Props {
  data: WaitingApprovalCardStoreData
}

const StoreWaitingApprovalCard = ({ data }: Props) => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardGrid>
        <CardIcon>
          <BTimePositive fontSize="default" />
        </CardIcon>
        <CardContent>
          <CardTitle>{t('cards.StoreWaitingApprovalCard.title')}</CardTitle>
          <CardSubtitle>{data.productDescription}</CardSubtitle>
          <CardDescription>
            {t('cards.StoreWaitingApprovalCard.description')}
          </CardDescription>
          <CardResume>
            <CardResumeBox>
              <CardResumeBoxLabel>
                {t('cards.StoreWaitingApprovalCard.label1')}
              </CardResumeBoxLabel>
              <CardResumeBoxHighlight color="bColorprimary04">
                {data.amount}
              </CardResumeBoxHighlight>
            </CardResumeBox>

            <CardResumeBox right={true}>
              <CardResumeBoxLabel>
                {t('cards.StoreWaitingApprovalCard.label2')}
              </CardResumeBoxLabel>
              <CardResumeBoxHighlight color="bColorprimary04">
                {data.totalInstallments}
              </CardResumeBoxHighlight>
            </CardResumeBox>
          </CardResume>
        </CardContent>
      </CardGrid>
    </Card>
  )
}

export { StoreWaitingApprovalCard }
