import { styled } from '@creditas/stylitas'
import { Button as ButtonRoot } from '@creditas/button'

export const CardGrid = styled.div`
  display: flex;
  align-items: center;
`

export const CardIconContainer = styled.div`
  > svg {
    font-size: 50px;
  }
`

export const CardTitleContainer = styled.div`
  margin-left: 8px;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-top: 5px;

  > svg {
    border: 1px solid #23a969;
    border-radius: 10px;
  }

  > h3 {
    font-weight: bold;
    margin-left: 5px;
  }
`

export const ContactsContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 450px) {
    flex-direction: column;
  }
`

export const Button = styled(ButtonRoot)`
  margin-top: 10px;
`
