import { styled, css } from '@creditas/stylitas'

const progressBar = ({ theme }) => css`
  margin-top: 24px;
  background: ${theme.bColorneutral03};
  border-radius: 15.5px;
  position: relative;
  height: 17px;
  width: 100%;

  &:after {
    border-radius: 15.5px;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: ${theme.bColorprimary04};
  }
`

export { progressBar }
