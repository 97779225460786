import { Product } from '../../interfaces'
import { ProductType, ProductStatus } from '../../../../enums'

export function isStoreWaitingApprovalCard({ type, status }: Product): boolean {
  return (
    type === ProductType.Product &&
    (status === ProductStatus.Pending ||
      status === ProductStatus.PendingFormalization)
  )
}
