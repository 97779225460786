import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from './loanContinueSolicitationCard.style'
import {
  Card,
  CardTitle,
  CardResume,
  CardResumeBox,
  CardDescription,
  CardResumeBoxLabel,
  CardResumeBoxHighlight,
} from '../Card'

import { ContinueSolicitationCardLoan } from '../../interfaces'

interface Props {
  data: ContinueSolicitationCardLoan
}

const LoanContinueSolicitationCard = ({ data }: Props) => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardTitle>{t('cards.LoanContinueSolicitationCard.title')}</CardTitle>
      <CardDescription>
        {t('cards.LoanContinueSolicitationCard.description')}
      </CardDescription>
      <CardResume>
        <CardResumeBox>
          <CardResumeBoxLabel>
            {t('cards.LoanContinueSolicitationCard.label1')}
          </CardResumeBoxLabel>
          <CardResumeBoxHighlight color={'bColorprimary04'}>
            {data.amount}
          </CardResumeBoxHighlight>
        </CardResumeBox>

        <CardResumeBox>
          <CardResumeBoxLabel>
            {t('cards.LoanContinueSolicitationCard.label2')}
          </CardResumeBoxLabel>
          <CardResumeBoxHighlight color={'bColorprimary04'}>
            {data.totalInstallments}
          </CardResumeBoxHighlight>
        </CardResumeBox>
      </CardResume>

      <Button
        variant="secondary"
        onClick={() => window.singleSpaNavigate(data.redirectTo)}
      >
        {t('cards.LoanContinueSolicitationCard.action')}
      </Button>
    </Card>
  )
}

export { LoanContinueSolicitationCard }
