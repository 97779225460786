import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Card,
  CardTitle,
  CardDescription,
  CardResume,
  CardResumeBox,
  CardResumeBoxLabel,
  CardResumeBoxHighlight,
} from '../Card'

import { Button } from './loanAcquittanceCard.style'
import { AcquittanceCardLoanData } from '../../interfaces'

interface Props {
  data: AcquittanceCardLoanData
}

const LoanAcquittanceCard = ({ data }: Props) => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardTitle>{t('cards.LoanAcquittanceCard.title')}</CardTitle>
      <CardDescription>
        {t('cards.LoanAcquittanceCard.description')}
      </CardDescription>
      <CardResume>
        <CardResumeBox>
          <CardResumeBoxLabel>
            {t('cards.LoanAcquittanceCard.label1')}
          </CardResumeBoxLabel>
          <CardResumeBoxHighlight color={'bColorprimary04'}>
            {data.amount}
          </CardResumeBoxHighlight>
        </CardResumeBox>

        <CardResumeBox right={true}>
          <CardResumeBoxLabel>
            {t('cards.LoanAcquittanceCard.label2')}
          </CardResumeBoxLabel>
          <CardResumeBoxHighlight color={'bColorprimary04'}>
            {data.totalInstallments}
          </CardResumeBoxHighlight>
        </CardResumeBox>
      </CardResume>

      <Button
        variant="secondary"
        onClick={() => window.singleSpaNavigate(data.redirectTo)}
      >
        {t('cards.LoanAcquittanceCard.action')}
      </Button>
    </Card>
  )
}

export { LoanAcquittanceCard }
